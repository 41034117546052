@import url('https://fonts.googleapis.com/css?family=Great+Vibes');
$signatureFont: "Great Vibes", "georgia", serif;

.psh {
  .cart.minimized {
    background: #eee;
    background-color: #000000;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23222' stroke-width='10' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
    border-bottom: 2px solid #444;
  }
  a.checkout-button.button {
    // font-family: 'Pacifico', sans-serif;
    background: red;
    font-weight: 800;
    // box-shadow: 5px 5px 2px solid black;
    // border: 2px solid #5a7e2a;
  }


  .category-name .text {
    font-family: $signatureFont;
    font-weight: 400;
    font-size: 1.8em;
    background: white;
    // border: 4px solid #5a7e2a;
    // border-radius: 1em;
    color: black;
  }
  // .category-name .text::before {
  //   content: "❍ "
  // }

  .order-benefits {
    /* color: white; */
    /* text-shadow: 0px 0px 4px rgba(255,255,255,1); */

    padding: 1em 0;
    margin: 0;
    // margin-top: -2.5em;
    display: block;
    min-height: 250px;
    /* max-height: 50vh; */
    // background: url(https://image.shutterstock.com/z/stock-photo-greek-food-background-meze-gyros-souvlaki-fish-pita-greek-salad-tzatziki-assortment-of-feta-646209769.jpg);
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_1200/cy5c90p9imowuqs17q2d);
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .order-benefits .text {
    font-family: futura, Avenir Next, sans-serif;
    color: white;
    background: rgba(0,0,0,0.5);
    padding: 0.5em 0.8em;
  }
}
